

import React, { useState } from 'react';
import { FaCheckCircle, FaCircle, FaTruck, FaMapMarkerAlt } from 'react-icons/fa';
import { CiSearch } from "react-icons/ci";
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css';

const OrderTracking = () => {
  const [waybillNumber, setWaybillNumber] = useState();
  const [orderTrackingInfo, setOrderTrackingInfo] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {isDarkMode,isToggleSidebar}= useMyContext();

  const handleTrack = () => {
    if (!waybillNumber) {
      setErrorMessage('Failed to fetch tracking information. Please try again.');
      setOrderTrackingInfo(null); 
      setIsExpanded(false); 
    
    }

    setOrderTrackingInfo({
      orderReceived: 'Nov 16, 11:20',
      processingInDubai: 'Nov 16, 12:35',
      readyToBeShipped: 'Nov 16, 15:00',
      shipped: 'Nov 16, 15:10',
      arrivedInRiyadh: 'Nov 18, 10:00',
      pickedUpByARAMEX: 'Nov 18, 10:30',
      arrivedInJeddah: 'Nov 18, 17:00',
      outForDelivery: 'Nov 19, 8:00',
      delivered: 'Nov 19, 13:00',
    });
    setIsExpanded(true);
    setErrorMessage(''); 
  };

  
  const getStepStatusIcon = (step) => {
    const deliveredSteps = ['delivered'];
    const inProgressSteps = ['orderReceived', 'processingInDubai', 'readyToBeShipped', 'shipped', 'arrivedInRiyadh', 'pickedUpByARAMEX', 'arrivedInJeddah', 'outForDelivery'];
  
    if (deliveredSteps.includes(step)) {
      return <FaCheckCircle className="text-green-500 w-6 h-6" />;
    }
    
    if (inProgressSteps.includes(step)) {
      return (
        <div className="relative inline-block">
          <svg className="w-7 h-7" viewBox="0 0 28 28">
            <circle cx="14" cy="14" r="13" fill="#EDE9FE" stroke="#8B5CF6" strokeWidth="2" />
          </svg>
          <FaTruck className="text-customPurple absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3.5 h-3.5" />
        </div>
      );
    }
    
    return <FaCircle className="text-gray-400 w-6 h-6" />;
  };

  const getDeliveryDate = () => {
    if (orderTrackingInfo) {
      return orderTrackingInfo.delivered;
    }
    return 'N/A';
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>

        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
              <Sidebar />
            </div>
          )}
    <div className="dark:bg-[rgba(1,14,78,0.96)] p-8 w-full mr-4 ml-4 mt-4 mb-4 md:w-[40%] md:mr-44  mx- md:ml-44 auto shadow-custom-light dark:shadow-custom-dark rounded-custom overflow-auto custom-scrollbar ">
      <h1 className="text-2xl font-bold dark:text-white mb-6 text-center text-customPurple ">Shipment Tracking</h1>
      <div className="mb-6">
        <label htmlFor="waybillNumber" className="block font-bold mb-2 dark:text-white">
          Enter Waybill Number
        </label>   
        <input
          type="text"
          id="waybillNumber"
          value={waybillNumber}
          onChange={(e) => setWaybillNumber(e.target.value)}
          className="w-full px-4 py-2 border dark:bg-[rgba(1,14,78,0.96)] dark:text-white  border-gray-300 rounded-custom shadow-custom-light dark:shadow-custom-dark focus:outline-none focus:ring-2 focus:ring-customPurple focus:border-purple-700  "
        />
        <button
          onClick={handleTrack}
          className="bg-customPurple hover:bg-purple-700 text-white font-bold dark:text-white py-2 px-4 rounded mt-4 w-full flex items-center justify-center"
        >
          <CiSearch className="mr-2" /> 
          Track My Shipments
        </button>
        {errorMessage && (
          <p className="text-red-500 mt-4">{errorMessage}</p>
        )}
      </div>

      {orderTrackingInfo && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-lg font-bold dark:text-white mb-4">Order Tracking</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                {getStepStatusIcon('orderReceived')}
                <div className="ml-4">
                  <p className="font-bold  dark:text-white">Order Received</p>
                  <p className="dark:text-white">{orderTrackingInfo.orderReceived}</p>
                </div>   
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('processingInDubai')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white">Processing in Dubai</p>
                  <p className="dark:text-white">{orderTrackingInfo.processingInDubai}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('readyToBeShipped')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white">Ready to be Shipped</p>
                  <p className="dark:text-white">{orderTrackingInfo.readyToBeShipped}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('shipped')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white">Shipped</p>
                  <p className="dark:text-white">{orderTrackingInfo.shipped}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('arrivedInRiyadh')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white">Arrived in Riyadh, KSA</p>
                  <p className="dark:text-white">{orderTrackingInfo.arrivedInRiyadh}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('pickedUpByARAMEX')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white"> on the way to Jeddah</p>
                  <p className="dark:text-white">{orderTrackingInfo.pickedUpByARAMEX}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('arrivedInJeddah')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white">Arrived in Jeddah</p>
                  <p className="dark:text-white">{orderTrackingInfo.arrivedInJeddah}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('outForDelivery')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white">Out for Delivery</p>
                  <p className="dark:text-white">{orderTrackingInfo.outForDelivery}</p>
                </div>
              </div>
              <div className="flex items-center">
                {getStepStatusIcon('delivered')}
                <div className="ml-4">
                  <p className="font-bold dark:text-white ">Delivered</p>
                  <p className="dark:text-white">{orderTrackingInfo.delivered}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-lg font-bold mb-4 dark:text-white">Delivery Info</h2>
            <div className="flex items-center">
              <FaMapMarkerAlt className=" text-customPurple mr-4" />
              <p className='dark:text-white'>Jeddah, Saudi Arabia</p>
            </div>
            <div className="flex items-center mt-4">
              {/* <FaTruck className=" text-customPurple mr-4" /> */}
              <p className='dark:text-white'>Estimated Delivery Date: {getDeliveryDate()}</p>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
    </div>
  );
};

export default OrderTracking;
