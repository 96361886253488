// NavBar.js
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdMenuOpen, MdOutlineMenu } from 'react-icons/md';
import { CiLight } from 'react-icons/ci';
import { FaWallet, FaBell, FaMoon } from 'react-icons/fa';
import { CgSearch } from "react-icons/cg";
import { useMyContext } from '../../context/MyContext';
import {toast} from 'react-hot-toast'
import { useAuth } from '../../routes/Auth';
const NavBar = () => {
  const { setIsToggleSidebar, isToggleSidebar, isDarkMode, toggleTheme } = useMyContext();
  const navigate = useNavigate();
  const [walletMenuVisible, setWalletMenuVisible] = useState(false);
  const walletMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const [userName, setUserName] = useState("");
  const [userMenu, setUserMenu] = useState(false);
  const {logout}=useAuth()

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser && storedUser.userData.name) {
      setUserName(storedUser.userData.name);
    }
  }, []);

  const toggleWalletMenu = () => {
    setWalletMenuVisible(!walletMenuVisible);
  };

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  }
  const goToTopUp = () => {
    navigate('/payments/walletbalance');
  };

  const handleLogout = () =>{
    localStorage.removeItem("user");
    navigate('/login')
    logout()
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
    toast.success("LogOut Sucessfully!")
   }

  const goToHistory = () => {
    navigate('/payments/history');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (walletMenuRef.current && !walletMenuRef.current.contains(event.target)) {
        setWalletMenuVisible(false);
      }
      else if (userMenuRef.current && !userMenuRef.current.contains(event.target))
      {
        setUserMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [walletMenuRef,userMenuRef]);

  return (
    <div className="flex flex-wrap items-center justify-between px-4 py-2  md:py-0 relative ">
      <div className="flex items-center">

        <Link to="/dashboard">
          <div className="flex items-center cursor-pointer pl-0 md:pl-0">
            <img src="/assets/images/logo1.png" alt="Logo" className="w-[100px] h-[70px] sm:w-[120px] sm:h-[50px] md:w-[190px] md:h-[100px]" />
          </div>
        </Link>
        <button
          onClick={() => setIsToggleSidebar(!isToggleSidebar)}
          className="ml-7 md:ml-10 text-blue-500 dark:text-blue-400"
        >
          {isToggleSidebar === false ? <MdMenuOpen size={24} /> : <MdOutlineMenu size={24} />}
        </button>
      </div>
     
      <div className="flex items-center space-x-2 md:space-x-4 pr-2 md:pr-24 order-2 md:order-3 relative">
        <button onClick={toggleTheme} className="text-blue-500 dark:text-blue-400 pr-4">
          {isDarkMode ? <CiLight size={24} className="text-white" /> : <FaMoon size={24} />}
        </button>
        <button onClick={toggleWalletMenu} className="text-blue-500 dark:text-blue-400 pr-4 relative">
          <FaWallet size={24} className={isDarkMode ? "text-white" : ""} />
          {walletMenuVisible && (
            <div
              ref={walletMenuRef}
              className="absolute right-0 mt-2 w-48 bg-white dark:bg-[#071a74f6] border border-gray-200  rounded-lg shadow-lg z-50"
            >
              <ul className="py-1 text-gray-700 dark:text-gray-200 dark:shadow-custom-dark">
                <li
                  className="py-2 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 dark:bg-[#071a74f6]"
                  onClick={goToHistory}
                >
                  Payments History
                </li>
                <li
                  className="py-2 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 dark:bg-[#071a74f6]"
                >
                  Balance : ₹400
                </li>
                <li
                  className="py-2 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 dark:bg-[#071a74f6]"
                  onClick={goToTopUp}
                >
                  Top-Up
                </li>
              </ul>
            </div>
          )}
        </button>
        <button className="text-blue-500 dark:text-blue-400 pr-4">
          <FaBell size={24} className={isDarkMode ? "text-white" : ""} />
        </button>
        <button
          onClick={toggleUserMenu}
          className={`text-white hidden md:flex rounded-md bg-blue-500 dark:text-white pr-4  items-center relative`}
        >
          <span
            className={`text-lg md:ml-4 md:text-lg font-semibold ${isDarkMode ? "text-white" : ""}`}
          >
            Welcome, {userName}
          </span>
          {userMenu && (
            <div
             ref={userMenuRef}
              className="absolute top-full mt-2 left-0 w-48 bg-white dark:bg-[#071a74f6] border border-gray-200 rounded-lg shadow-lg z-50"
            >
              <ul className="py-1 text-gray-700 dark:text-gray-200 dark:shadow-custom-dark">
                <li
                  className="py-2 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 dark:bg-[#071a74f6]"
                // onClick={goToHistory}
                >
                  Profile
                </li>
                <li onClick={handleLogout}
                  className="py-2 cursor-pointer hover:bg-gray-100 hover:dark:bg-purple-700 dark:bg-[#071a74f6]"
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </button>

      </div>
      <div className="flex-grow mx-2 md:mx-14 lg:pl-20 order-3 md:order-2 w-full md:w-auto mt-4 md:mt-0">
        <div className="relative flex items-center bg-gray-100 dark:bg-[#071a74f6] rounded-md w-full md:w-[90%]">
          <CgSearch className={`absolute left-3 ${isDarkMode ? "dark:text-white" : "text-custom-gray"}`} size={24} />
          <input
            type="text"
            placeholder="Search with LRN/AWB/OID"
            className={`font-sans bg-gray-200 dark:bg-[#071a74f6] w-full h-[40px] border-0 outline-none rounded-[10px] pl-[50px] pr-[10px] bg-opacity-70 text-[12px] md:text-[22px] ${isDarkMode ? "placeholder-white" : "placeholder-custom-gray"} font-san dark:text-white`}
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
