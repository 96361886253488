"use client";
import { useState } from 'react';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import { Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { FaWeight } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css'
const WeightDisputeDashboard = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { isDarkMode,isToggleSidebar } = useMyContext();

  const tabs = [
    { id: 'all', label: 'All Shipments' },
    { id: 'action', label: 'Action Required Count' },
    { id: 'open', label: 'Open Count' },
    { id: 'closed', label: 'Closed Count' },
    { id: 'accepted', label: 'Accepted' },
    { id: 'disputes', label: 'All Disputes' },
  ];

  const actionRequiredData = [
    { awbNo: 'AWB001', enterWeight: '10kg', enterDimensions: '30x20x10', productDescription: 'Electronics', sku: 'SKU001', chargeableWeight: '12kg', price: '$100' },
    { awbNo: 'AWB002', enterWeight: '5kg', enterDimensions: '20x15x10', productDescription: 'Clothing', sku: 'SKU002', chargeableWeight: '6kg', price: '$50' },
  ];

  const openCountData = [
    { awbNo: 'AWB003', enterWeight: '8kg', enterDimensions: '25x20x15', productDescription: 'Books', sku: 'SKU003', chargeableWeight: '10kg', price: '$80' },
    { awbNo: 'AWB004', enterWeight: '15kg', enterDimensions: '40x30x20', productDescription: 'Furniture', sku: 'SKU004', chargeableWeight: '18kg', price: '$200' },
    { awbNo: 'AWB003', enterWeight: '8kg', enterDimensions: '25x20x15', productDescription: 'Books', sku: 'SKU003', chargeableWeight: '10kg', price: '$80' },
    { awbNo: 'AWB003', enterWeight: '8kg', enterDimensions: '25x20x15', productDescription: 'Books', sku: 'SKU003', chargeableWeight: '10kg', price: '$80' },
    { awbNo: 'AWB003', enterWeight: '8kg', enterDimensions: '25x20x15', productDescription: 'Books', sku: 'SKU003', chargeableWeight: '10kg', price: '$80' },
    
  ];

  // const handleSelectAll = () => {
  //   setSelectAll(!selectAll);
  //   setSelectedRows(selectAll ? [] : actionRequiredData.map((_, index) => index));
  // };

  // const handleRowSelect = (index) => {
  //   if (selectedRows.includes(index)) {
  //     setSelectedRows(selectedRows.filter((i) => i !== index));
  //   } else {
  //     setSelectedRows([...selectedRows, index]);
  //   }
  // };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
      <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
      {isToggleSidebar && (
        <div
          className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
          style={
            window.innerWidth < 768 
              ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
              : {}
          }>
          <Sidebar />
        </div>
      )}
      <div className="container mx-auto p-4 shadow-custom-light rounded-custom md:mt-4 md:mb-4 md:mr-4 md:ml-4 sm:mb-2 sm:ml-2 sm:mr-2 sm:mt-8 dark:shadow-custom-dark custom-scrollbar overflow-y-auto">
        {/* <h1 className="text-2xl font-bold mb-4">Weight Dispute</h1> */}
        <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
          <FaWeight className="mr-2" size={24} />
          Weight Disputes
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`px-4 py-2 mr-8 w-full mb-2 rounded dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark ${activeTab === tab.id
                ? 'bg-customPurple hover:bg-purple-700 dark:hover:bg-purple-700 text-white dark:bg-customPurple dark:text-white'
                : 'dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'
                }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className="overflow-x-auto shadow-custom-light rounded-custom dark:shadow-none  ">
          {activeTab === 'action' && (
            <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
              <div className='overflow-x-auto'>
                <table className='min-w-full table-auto'>
                  <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                    <tr>
                      {/* <th className='p-2 text-left'>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th> */}
                      <th className='p-2 text-left'>AWB No</th>
                      <th className='p-2 text-left'>Enter Weight</th>
                      <th className='p-2 text-left'>Enter Dimensions</th>
                      <th className='p-2 text-left'>Product Description</th>
                      <th className='p-2 text-left'>SKU</th>
                      <th className='p-2 text-left'>Chargeable Weight</th>
                      <th className='p-2 text-left'>Price</th>
                      <th className='p-2 text-left'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actionRequiredData.map((row, index) => (
                      <tr key={index} className='border-b'>
                        {/* <td className='p-2'>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(index)}
                            onChange={() => handleRowSelect(index)}
                          />
                        </td> */}
                        <td className='p-2'>{row.awbNo}</td>
                        <td className='p-2'>{row.enterWeight}</td>
                        <td className='p-2'>{row.enterDimensions}</td>
                        <td className='p-2'>{row.productDescription}</td>
                        <td className='p-2'>{row.sku}</td>
                        <td className='p-2'>{row.chargeableWeight}</td>
                        <td className='p-2'>{row.price}</td>
                        <td className='p-2'>
                          <Button
                            variant="contained"
                            component="label"
                            size="small"
                            className='capitalize bg-blue-400'
                          >
                            <FiUpload size={20} />
                            <input
                              type="file"
                              hidden
                              accept="image/*"
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex justify-between items-center mt-4'>
                <p>Showing <b>1</b> of <b>50</b> results</p>
                <Pagination count={10} color="primary" size="small" />
              </div>
            </div>
          )}

          {activeTab === 'open' && (
            <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
              <div className='overflow-x-auto'>
                <table className='min-w-full table-auto'>
                  <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                    <tr>
                      {/* <th className='p-2 text-left'>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th> */}
                      <th className='p-2 text-left'>AWB No</th>
                      <th className='p-2 text-left'>Enter Weight</th>
                      <th className='p-2 text-left'>Enter Dimensions</th>
                      <th className='p-2 text-left'>Product Description</th>
                      <th className='p-2 text-left'>SKU</th>
                      <th className='p-2 text-left'>Chargeable Weight</th>
                      <th className='p-2 text-left'>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openCountData.map((row, index) => (
                      <tr key={index} className='border-b'>
                        {/* <td className='p-2'>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(index)}
                            onChange={() => handleRowSelect(index)}
                          />
                        </td> */}
                        <td className='p-2'>{row.awbNo}</td>
                        <td className='p-2'>{row.enterWeight}</td>
                        <td className='p-2'>{row.enterDimensions}</td>
                        <td className='p-2'>{row.productDescription}</td>
                        <td className='p-2'>{row.sku}</td>
                        <td className='p-2'>{row.chargeableWeight}</td>
                        <td className='p-2'>{row.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='flex justify-between items-center mt-4'>
                <p>Showing <b>1</b> of <b>50</b> results</p>
                <Pagination 
                 sx={{
                  '.MuiPaginationItem-text': {
                    color: isDarkMode ? 'white' : 'defaultColor', 
                  },
                  '.MuiPaginationItem-root': {
                  },
                }}
                 count={10} color="primary" size="small" />
              </div>
            </div>
          )}

          {(activeTab === 'all' || activeTab === 'closed' || activeTab === 'accepted' || activeTab === 'disputes') && (
            <p className="text-gray-600">No data available for this tab.</p>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default WeightDisputeDashboard;
