import React, { useState, useEffect } from 'react';
import { IoMdEye } from 'react-icons/io';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from "react-icons/md";
import { Button, Pagination } from '@mui/material';
import { json, useNavigate } from 'react-router-dom';
import ViewWarehouse from '../../../component/models/ViewWarehouse';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import '../../../css/custom-scrollbar.css';
import { LuWarehouse } from "react-icons/lu";
import axios from 'axios';
import { Base_Url, Warehouselist } from '../../../config/config';

const AllWarehouses = () => {
  const { isDarkMode } = useMyContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const { isToggleSidebar } = useMyContext();
  const [warehouses, setWarehouses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const itemsPerPage = 8;
  const navigate = useNavigate();
  
  

  const fetchWarehouses = async () => {
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');

    try {
      const response = await fetch(Base_Url+Warehouselist, {
        method: 'GET',
        headers: {
        'token': token,
            'cus_id': cusid,
            'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === "SUCCESS" && Array.isArray(result.data)) {
        setWarehouses(result.data);
      } else {
        console.error('Unexpected response format:', result);
      }
    } catch (error) {
      console.error('Error fetching warehouse data:', error);
    }
  };

  useEffect(() => {
    fetchWarehouses();
    
  }, []);

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = warehouses.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleViewClick = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  // const handleSelectAll = (e) => {
  //   if (e.target.checked) {
  //     setSelectedRows([...Array(currentItems.length).keys()]); 
  //   } else {
  //     setSelectedRows([]);
  //   }
  //   setSelectAll(e.target.checked);
  // };

  // const handleRowSelect = (index) => {
  //   setSelectedRows((prev) => {
  //     const isSelected = prev.includes(index);
  //     if (isSelected) {
  //       return prev.filter((i) => i !== index);
  //     } else {
  //       return [...prev, index];
  //     }
  //   });
  // };

  const handleNavigation = () => {
    navigate('/dashboard');
  };



  // const handleDelete = async (id) => {
  //   try {
  //     const response = await axios.delete('https://dev.pickupxpress.com/api/deletewarehouse', {
  //       headers: {
  //         token: userData.userData.token,
  //         cus_id: userData.userData.cus_id,
  //         'Content-Type': 'application/json',
  //         'Accept': 'application/json',
  //       },
  //       data: { id }  
  //     });

  //     if (response.data.status === "SUCCESS") {
  //       setWarehouses((prevWarehouses) => prevWarehouses.filter(warehouse => warehouse.id !== id));
  //     } else {
  //       console.error('Unexpected response format:', response.data);
  //     }
  //   } catch (error) {
  //     console.error('Error deleting warehouse:', error);
  //   }
  // };

  return (
    <>
      <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>

        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
              <Sidebar />
            </div>
          )}

          <div className={`shadow-custom-light ml-3 mt-6 mb-6 mr-6 w-full rounded-custom overflow-y-auto dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>

            <button onClick={handleNavigation}
              className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-[60%] sm:w-full flex items-center justify-center">
              <LuWarehouse className="mr-2" size={24} />
              All WareHouses
            </button>

            <div className="overflow-x-auto">
              <table className="min-w-full w-full table-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr>
                    <th className="p-2 text-left w-1/12">ID</th>
                    <th className="p-2 text-left w-2/12">Name</th>
                    <th className="p-2 text-left w-3/12">Email</th>
                    <th className="p-2 text-left w-2/12">Phone</th>
                    <th className="p-2 text-left w-2/12">City</th>
                    <th className="p-2 text-left w-2/12">State</th>
                    <th className="p-2 text-left w-2/12">Country</th>
                    <th className="p-2 text-left w-2/12 pl-12">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((warehouse, index) => (
                    <tr key={warehouse.id} className="w-full">
                      <td className="p-2">{warehouse.customer_id}</td>
                      <td className="p-2">{warehouse.name}</td>
                      <td className="p-2">{warehouse.email}</td>
                      <td className="p-2">{warehouse.phone}</td>
                      <td className="p-2">{warehouse.city}</td>
                      <td className="p-2">{warehouse.state}</td>
                      <td className="p-2">{warehouse.country}</td>
                      <td className="p-2">
                        <div className="flex space-x-2">
                          <Button variant="contained" size="small" color="primary" onClick={() => handleViewClick(warehouse)}>
                            <IoMdEye />
                          </Button>
                          <Button variant="contained" size="small" color="error">
                            <MdDelete />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


            <div className="flex justify-between items-center mt-4">
              <p>Showing <b>{indexOfFirstItem + 1}</b> to <b>{Math.min(indexOfLastItem, warehouses.length)}</b> of <b>{warehouses.length}</b> results</p>
              <Pagination
                count={Math.ceil(warehouses.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  '.MuiPaginationItem-text': {
                    color: isDarkMode ? 'white' : 'defaultColor', 
                  },
                  '.MuiPaginationItem-root': {
                  },
                }}
                color="primary"
                size="small"
              />
            </div>
          </div>
        </div>

        <ViewWarehouse isopen={isPopupOpen} onClose={handleClose} warehouse={selectedWarehouse} />
      </div>
    </>
  );
};

export default AllWarehouses;
