"use client"
import React from 'react';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { useMyContext } from '../../../context/MyContext';
import Pagination from '@mui/material/Pagination';
import '../../../css/custom-scrollbar.css';
import { useNavigate } from 'react-router-dom';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';

const PaymentHistory = () => {
    const {isDarkMode,isToggleSidebar} = useMyContext();
    const navigation =useNavigate();
     
    const handleNavigation =()=>{
      navigation("/dashboard");
    }

  const historyData = [

    { id: '1', amount: 100, lastBalance: 500, type: 'Credit', detail: 'Recharge', status: 'Success', date: '2024-08-29', user: 'John Doe' },
    { id: '2', amount: 50, lastBalance: 450, type: 'Debit', detail: 'Purchase', status: 'Completed', date: '2024-08-28', user: 'Jane Smith' },
    { id: '1', amount: 100, lastBalance: 500, type: 'Credit', detail: 'Recharge', status: 'Success', date: '2024-08-29', user: 'John Doe' },
    { id: '2', amount: 50, lastBalance: 450, type: 'Debit', detail: 'Purchase', status: 'Completed', date: '2024-08-28', user: 'Jane Smith' },
    { id: '1', amount: 100, lastBalance: 500, type: 'Credit', detail: 'Recharge', status: 'Success', date: '2024-08-29', user: 'John Doe' },
    { id: '2', amount: 50, lastBalance: 450, type: 'Debit', detail: 'Purchase', status: 'Completed', date: '2024-08-28', user: 'Jane Smith' },
  ];

  const renderTableHeaders = () => {
    return ['OrderNo', 'Amount', 'Last Balance', 'Transaction Type', 'Transaction Detail', 'Transaction Status', 'Generate Date', 'User'];
  };

  const renderTableRow = (row) => (
    <tr key={row.id} className="border-b dark:border-gray-600">
      <td className="p-2">{row.id}</td>
      <td className="p-2">{row.amount}</td>
      <td className="p-2">{row.lastBalance}</td>
      <td className="p-2">{row.type}</td>
      <td className="p-2">{row.detail}</td>
      <td className="p-2">{row.status}</td>
      <td className="p-2">{row.date}</td>
      <td className="p-2">{row.user}</td>
    </tr>
  );

  return (
       
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
        <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
            <div
                className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                style={
                  window.innerWidth < 768 
                    ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                    : {}
                }>
                <Sidebar />
            </div>
        )}
      <div className="dark:bg-[rgba(1,14,78,0.96)] w-full dark:text-white shadow-custom-light dark:shadow-none rounded-custom mt-4 p-4  md:ml-4 md:mt-4 md:mb-8 md:mr-4  custom-scrollbar overflow-y-auto">
      <button  onClick={handleNavigation} 
                    className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 dark:bg-customPurple dark:hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
                        <FaCcVisa className="mr-2" size={24} />
                        <FaCcMastercard className="mr-2" size={24} />
                        Payment History
                    </button>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-[#0540a6dd] text-white text-[14px]">
              <tr>
                {renderTableHeaders().map((header, index) => (
                  <th key={index} className="p-2 text-left">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {historyData.map(row => renderTableRow(row))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <p>Showing <b>1</b> of <b>50</b> results</p>
          <Pagination
           sx={{
            '.MuiPaginationItem-text': {
              color: isDarkMode ? 'white' : 'defaultColor', 
            },
            '.MuiPaginationItem-root': {
            },
          }}
           count={10} color="primary" size="small" />
        </div>
      </div>
      </div>
      </div>
  );
};

export default PaymentHistory;
