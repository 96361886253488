
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import React from 'react';
import { useMyContext } from '../../context/MyContext';
import { RiMoneyRupeeCircleFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";


const ViewRateForword = ({ isopen, onClose, data }) => {
  const { isDarkMode } = useMyContext();
  const navigate = useNavigate();

  const handleNaviagtion = () => {
    navigate('/shipments/forward');
  };

  const handleClose = () => {
    onClose();
  };


  const tableData = data && data.data && data.data.partners_pricing ? [
    ...(data.data.partners_pricing.Delhivery ? Object.entries(data.data.partners_pricing.Delhivery).map(([mode, pricing], index) => ({
      id: `Delhivery-${mode}-${index}`,
      courier: "delhivery",
      mode,
      zone: data.data.zone,
      basePrice: pricing.total_price,
      codExtra: pricing.cod_extra,
      codTotalPrice: pricing.cod_total_price,
      shippingMode: mode
    })) : []),
    ...(data.data.partners_pricing.Shiprocket ? Object.entries(data.data.partners_pricing.Shiprocket).map(([mode, pricing], index) => ({
      id: `Shiprocket-${mode}-${index}`,
      courier: "shiprocket",
      mode,
      zone: data.data.zone,
      basePrice: pricing.total_price,
      codExtra: pricing.cod_extra,
      codTotalPrice: pricing.cod_total_price,
      shippingMode: mode
    })) : [])
  ] : [];

  return (
    <Dialog
    className='shadow-custom-light dark:shadow-custom-dark rounded-custom'
      open={isopen}
      onClose={onClose}
      maxWidth="lg"
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          height: '80vh',
        }
      }}
    >
      <DialogTitle className="bg-customPurple text-white text-center">
        <h2 className="text-2xl font-semibold flex items-center justify-center">
          <RiMoneyRupeeCircleFill />
          Rate Calculator
        </h2>
        <IoIosCloseCircle
          className="absolute top-2 right-2 cursor-pointer"
          size={32}
          onClick={handleClose}
        />  
      </DialogTitle>

      <DialogContent
        className={`shadow-custom-light rounded-custom dark:shadow-custom-dark mt-4 ml-2 mr-2 mb-4 p-4 
        ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}
        sx={{ overflowY: 'auto' }}  
      >
        <div className="overflow-x-auto md:mt-8 md:mb-8 md:ml-8 md:mr-8">
          <table border={1} className="min-w-full mt-3 md:mt-5 table-auto">
            <thead className="bg-[#0540a6dd] text-white text-[12px]">
              <tr>
                <th className="p-2 text-left">Courier Name</th>
                <th className="p-2 text-left">Mode</th>
                <th className="p-2 text-left">Zone</th>
                <th className="p-2 text-left">Forward Price</th>
                <th className="p-2 text-left">COD Price</th>
                <th className="p-2 text-left">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? tableData.map((row) => (
                <tr key={row.id} className="border-b">
                  <td className="p-2">{row.courier}</td>
                  <td className="p-2">{row.mode}</td>
                  <td className="p-2">{row.zone}</td>
                  <td className="p-2">{`₹ ${row.basePrice}`}</td>
                  <td className="p-2">{`₹ ${row.codExtra}`}</td>
                  <td className="p-2">{`₹ ${row.codTotalPrice}`}</td>
                </tr>
              )) : (
                <tr>
                  <td className="p-2 text-center" colSpan="7">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DialogContent>
      <button onClick={handleNaviagtion}
          className="w-full md:w-[20%]  font-bold font-sans md:self-end ml-24 mb-2 text-black py-2  rounded-md flex items-center">
          <IoMdArrowRoundBack className="mr-2" />
          Create Forward Order
        </button>
    </Dialog>
  );
};

export default ViewRateForword;
