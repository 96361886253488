import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { MdCallMade } from "react-icons/md";
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar'
import { useMyContext } from '../../../context/MyContext';
// Define the validation schema with Zod
const schema = z.object({
  pickupLocation: z.string().min(1, { message: "Pickup location is required" }),
  date: z.string().min(1, { message: "Date is required" }),
  boxCount: z.number().min(1, { message: "Box count must be at least 1" }),
  pickupTiming: z.string().min(1, { message: "Pickup timing is required" }),
});

export default function PickupRequest() {
  // Handle form submission
  const {isDarkMode,isToggleSidebar} = useMyContext()
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema)
  });

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission logic here
  };

  return (
    <>
    
<div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
            <Sidebar />
          </div>
        )}
    <div className="w-full shadow-custom-light rounded-custom  dark:shadow-none">
    <div className={`wrapper-class max-w-full mx-auto md:mx-0 mt-2 md:pl-40 md:pt-8 md:pb-8 border-gray-200  font-sans bg-transparent ${isDarkMode?"dark:bg-[rgba(1,14,78,0.96)] text-white ":""}`}>
  <div className="max-w-full md:max-w-[60%] mx-auto md:mx-0 mt-2 p-6 border-gray-200 md:ml-8 font-sans shadow-custom-light dark:shadow-custom-dark rounded-custom">
    <h2 className="text-2xl font-semibold mb-6 bg-customPurple text-white py-2 px-4 rounded-t-lg text-center">
      Pickup Request Creation
    </h2>

    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label className="block text-sm font-medium  mb-1">
          Select Pickup Location
        </label>
        <select 
          {...register('pickupLocation')}
          className={`p-2 appearance-none border w-full focus:outline-none focus:shadow-outline shadow-custom-light rounded-custom ${isDarkMode?"dark:bg-[rgba(1,14,78,0.96)] text-white  dark:shadow-custom-dark":""}`}
        >
          <option value="">Select location</option>
          <option value="location1">Location 1</option>
          <option value="location2">Location 2</option>
          <option value="location3">Location 3</option>
          <option value="location4">Location 4</option>
        </select>
        {errors.pickupLocation && <p className="text-red-500 text-xs mt-1">{errors.pickupLocation.message}</p>}
      </div>

      <div>
        <label className="block text-sm font-medium  mb-1">
          Select Date
        </label>
        <input
          type="date"
          {...register('date')}
                   className={`p-2 appearance-none border w-full focus:outline-none focus:shadow-outline shadow-custom-light rounded-custom ${isDarkMode?"dark:bg-[rgba(1,14,78,0.96)] text-white  dark:shadow-custom-dark":""}`}

        />
        {errors.date && <p className="text-red-500 text-xs mt-1">{errors.date.message}</p>}
      </div>

      <div>
        <label className="block text-sm font-medium  mb-1">
          Box Count
        </label>
        <input
          type="number"
          {...register('boxCount', { valueAsNumber: true })}
                 className={`p-2 appearance-none border w-full focus:outline-none focus:shadow-outline shadow-custom-light rounded-custom ${isDarkMode?"dark:bg-[rgba(1,14,78,0.96)] text-white  dark:shadow-custom-dark":""}`}

          placeholder="No of boxes"
        />
        {errors.boxCount && <p className="text-red-500 text-xs mt-1">{errors.boxCount.message}</p>}
      </div>

      <div>
        <label className="block text-sm font-medium  mb-1">
          Select Pickup Timing
        </label>
        <select 
          {...register('pickupTiming')}
                   className={`p-2 appearance-none border w-full focus:outline-none focus:shadow-outline shadow-custom-light  rounded-custom ${isDarkMode?"dark:bg-[rgba(1,14,78,0.96)] text-white  dark:shadow-custom-dark":""}`}

        >
          <option value="">Select timing</option>
          <option value="morning">Morning</option>
          <option value="afternoon">Afternoon</option>
          <option value="evening">Evening</option>
        </select>
        {errors.pickupTiming && <p className="text-red-500 text-xs mt-1">{errors.pickupTiming.message}</p>}
      </div>

      <button 
        type="submit"
        className="w-full mt-6 bg-customPurple text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50 flex items-center justify-center"
      >
        Create Request <MdCallMade className="ml-2" />
      </button>
    </form>
  </div>
 </div>
 </div>
 </div>
 </div>
 </>
  );
}
