import { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import NavBar from '../../../component/navBar/navBar'
import Sidebar from '../../../component/sidebar/sidebar';
import { useForm } from 'react-hook-form';
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaCalculator, FaUser, FaShoppingCart, FaBox } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../../context/MyContext';


const schema = z.object({
    warehouse: z.string().min(1, "Warehouse is required"),
    firstName: z.string().min(1, "First Name is required").regex(/^[a-zA-Z\s]+$/, "First Name must only contain alphabetic characters"),
    lastName: z.string().min(1, "Last Name is required").regex(/^[a-zA-Z\s]+$/, "Last Name must only contain alphabetic characters"),
    email: z.string().email("Invalid email address"),
    mobile: z.string()
        .length(10, "Mobile number must be exactly 10 digits")
        .regex(/^\d+$/, "Mobile number must be numeric"),
    address: z.string().min(1, "Full Address is required"),
    pincode: z.string()
        .min(6, "Pincode should be exactly 6 characters")
        .max(6, "Pincode should be exactly 6 characters")
        .regex(/^\d+$/, "Pincode must be numeric"),
    city: z.string().min(1, "City is required").regex(/^[a-zA-Z\s]+$/, "City must only contain alphabetic characters"),
    state: z.string().min(1, "State is required").regex(/^[a-zA-Z\s]+$/, "State must only contain alphabetic characters"),
    orderId: z.string().min(1, "Order ID is required").regex(/^\d+$/, "Order ID must be numeric"),
    productDescription: z.string().min(1, "Product Description is required"),
    paymentMode: z.string().min(1, "Payment Mode is required"),
    codAmount: z.string().min(1, "COD Amount is required").regex(/^\d+$/, "COD Amount must be positive"),
    weight: z.string().min(1, "Weight is required").regex(/^\d+$/, "Weight must be positive"),
    boxQty: z.string().min(1, "Number of Boxes is required").regex(/^\d+$/, "Box Quantity must be positive"),
    length: z.string().min(1, "Length is required").regex(/^\d+$/, "Length must be positive"),
    width: z.string().min(1, "Width is required").regex(/^\d+$/, "Width must be positive"),
    height: z.string().min(1, "Height is required").regex(/^\d+$/, "Height must be positive"),
});

export default function ReverseShipment() {



    const { isDarkMode,isToggleSidebar } = useMyContext()
    const [loading, setLoading] = useState(true);
    const Navigation = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: zodResolver(schema),
    });



    const handleNaviagtion =()=>{
        Navigation("/dashboard");
    }
    

    useEffect(() => {
        // Simulate loading data
        const fetchData = async () => {
            // Simulate a data fetch with a timeout
            setTimeout(() => {
                setLoading(false);
            }); // Adjust the timeout as needed
        };

        fetchData();
    }, []);


    const handleInputChange = (event) => {
        const { value, id } = event.target;

        // Allow only digits for numeric fields
        if (['pincode', 'mobile', 'weight', 'boxQty', 'length', 'width', 'height'].includes(id)) {
            const filteredValue = value.replace(/[^0-9]/g, '');
            event.target.value = filteredValue;
        }

        // Allow only letters and spaces for text fields
        if (['firstName', 'lastName', 'city', 'state', "paymentMode"].includes(id)) {
            const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
            event.target.value = filteredValue;
        }
    };



    const onSubmit = async (data) => {
        try {
            console.log("form data", data);
            const response = await axios.post('YOUR_API_ENDPOINT', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                // toast.success('Forword shipment created successfully!');
                reset();
            } else {
                throw new Error('Failed to create reverse shipment');
            }
        } catch (error) {
            console.error(error);
            // toast.error('An error occurred while creating the Forword shipment');
        }
    }

    return (
        <>

            <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
                <div className="z-10">
                    <NavBar />
                </div>
                <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div
                        className={` fixed z-20 h-full w-64 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:relative md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768 
                              ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                              : {}
                          }  >
                        <Sidebar />
                    </div>
                    )}
                    <div
                    // {`${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white shadow-none" : ""} w-full  overflow-auto transition-all duration-300  shadow-custom-light rounded-custom }`}
                    className={`flex-1 sm:mt-3 overflow-auto transition-all duration-300 ${isToggleSidebar ? 'md:ml-0' : 'md:ml-0'} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-dark" : "bg-white"}`}
                    >
                        <div className="container mx-auto pl-6 pr-6 pt-4 pb-2 ">
                            <div className="flex flex-col md:flex-row mb-3 gap-2 md:gap-8">
                                <button onClick={handleNaviagtion}
                                 className="w-full md:w-[48%] bg-customPurple hover:bg-purple-700 text-white py-2 rounded-md flex items-center justify-center">
                                    <IoMdArrowRoundBack className="mr-2" />
                                    Create Reverse Order
                                </button>
                                <button className="w-full md:w-[48%] bg-customPurple hover:bg-purple-700 text-white py-2 rounded-md flex items-center justify-center">
                                    <FaBox className="mr-2" />
                                    Upload Bulk
                                </button>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="px-8 pt-6 pb-8 mb-4 font-sans shadow-custom-light dark:shadow-custom-dark  rounded-custom w-full">
                                    <h2 className="text-xl font-bold mb-4 text-custom-blue flex items-center">
                                        <FaUser className="mr-2 text-custom-blue" />
                                        Customer Details
                                    </h2>
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                                        <div>
                                            <label htmlFor="warehouse" className="block text-sm font-medium mb-1">Warehouse</label>
                                            <select id="warehouse" {...register("warehouse")} className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[#010e4e] text-white placeholder-white shadow-none" : ''}`}>
                                                <option>Select warehouse</option>
                                                {/* Add options here */}
                                            </select>
                                            {errors.warehouse && <p className="text-red-500 text-sm mt-1">{errors.warehouse.message}</p>}
                                        </div>

                                        <div>
                                            <label htmlFor="firstName" className="block text-sm font-medium mb-1">First Name</label>
                                            <input
                                                id="firstName"
                                                {...register("firstName")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="Enter first name"
                                            />
                                            {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="lastName" className="block text-sm font-medium mb-1">Last Name</label>
                                            <input
                                                id="lastName"
                                                {...register("lastName")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="Last name"
                                            />
                                            {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium mb-1">Email</label>
                                            <input
                                                id="email"
                                                {...register("email")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="email"
                                                placeholder="Enter email"
                                            />
                                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="mobile" className="block text-sm font-medium mb-1">Mobile No.</label>
                                            <input
                                                id="mobile"
                                                maxLength="10"
                                                {...register("mobile")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="tel"
                                                onChange={handleInputChange}
                                                placeholder="Enter 10 digit mobile no."
                                            />
                                            {errors.mobile && <p className="text-red-500 text-sm mt-1">{errors.mobile.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="address" className="block text-sm font-medium mb-1">Full Address</label>
                                            <input
                                                id="address"
                                                {...register("address")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                placeholder="Enter full address"
                                            />
                                            {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="pincode" className="block text-sm font-medium mb-1">Pincode</label>
                                            <input
                                                id="pincode"
                                                maxLength="6"
                                                {...register("pincode")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="Enter pincode"
                                            />
                                            {errors.pincode && <p className="text-red-500 text-sm mt-1">{errors.pincode.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="city" className="block text-sm font-medium mb-1">City</label>
                                            <input
                                                id="city"
                                                {...register("city")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="City"
                                            />
                                            {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city.message}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="state" className="block text-sm font-medium mb-1">State</label>
                                            <input
                                                id="state"
                                                {...register("state")}
                                                className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                type="text"
                                                onChange={handleInputChange}
                                                placeholder="State"
                                            />
                                            {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state.message}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col md:flex-row gap-2 font-sans w-full">
                                    <div className="px-8 pt-6 pb-8 mb-4 flex-1  shadow-custom-light dark:shadow-custom-dark rounded-custom">
                                        <h2 className="text-xl font-bold mb-4 text-custom-blue flex items-center">
                                            <FaShoppingCart className="mr-2 text-custom-blue" />
                                            Order Details
                                        </h2>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                            <div>
                                                <label htmlFor="orderId" className="block text-sm font-medium mb-1">Order ID</label>
                                                <input
                                                    id="orderId"
                                                    {...register("orderId")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Enter Order ID"
                                                />
                                                {errors.orderId && <p className="text-red-500 text-sm mt-1">{errors.orderId.message}</p>}
                                            </div>
                                            <div>
                                                <label htmlFor="productDescription" className="block text-sm font-medium mb-1">Product Description</label>
                                                <input
                                                    id="productDescription"
                                                    {...register("productDescription")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Product Description"
                                                />
                                                {errors.productDescription && <p className="text-red-500 text-sm mt-1">{errors.productDescription.message}</p>}
                                            </div>
                                            <div>
                                                <label htmlFor="paymentMode" className="block text-sm font-medium mt-4 mb-1">Payment Mode</label>
                                                <select
                                                    id="paymentMode"
                                                    {...register("paymentMode")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select Payment Mode</option>
                                                    <option value="cod">COD</option>
                                                    <option value="prepaid">Prepaid</option>
                                                </select>
                                                {errors.paymentMode && <p className="text-red-500 text-sm mt-1">{errors.paymentMode.message}</p>}
                                            </div>

                                            <div>
                                                <label htmlFor="codAmount" className="block text-sm mt-4 font-medium mb-1">Amount</label>
                                                <input
                                                    id="codAmount"
                                                    {...register("codAmount")}
                                                    className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                    type="text"
                                                    placeholder="Amount"
                                                />
                                                {errors.codAmount && <p className="text-red-500 text-sm mt-1">{errors.codAmount.message}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="px-8 pt-6 pb-8 mb-4 flex-1 font-sans dark:shadow-custom-dark  shadow-custom-light rounded-custom">
                                        <h2 className="text-xl font-bold mb-4 text-custom-blue flex items-center">
                                            <FaBox className="mr-2 text-custom-blue" />
                                            Box Details
                                        </h2>
                                        <div className="grid grid-cols-1 gap-2">
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
                                                <div>
                                                    <label htmlFor="weight" className="block text-sm font-medium mb-1">Weight</label>
                                                    <input
                                                        id="weight"
                                                        {...register("weight")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="text"
                                                        placeholder="Weight"
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.weight && <p className="text-red-500 text-sm mt-1">{errors.weight.message}</p>}
                                                </div>
                                                <div>
                                                    <label htmlFor="boxQty" className="block text-sm font-medium mb-1">Box Quantity</label>
                                                    <input
                                                        id="boxQty"
                                                        {...register("boxQty")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="text"
                                                        onChange={handleInputChange}
                                                        placeholder="Box Quantity"
                                                    />
                                                    {errors.boxQty && <p className="text-red-500 text-sm mt-1">{errors.boxQty.message}</p>}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-4">
                                                <div>
                                                    <label htmlFor="length" className="block text-sm font-medium mb-1">Length</label>
                                                    <input
                                                        id="length"
                                                        {...register("length")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="text"
                                                        onChange={handleInputChange}
                                                        placeholder="Length"
                                                    />
                                                    {errors.length && <p className="text-red-500 text-sm mt-1">{errors.length.message}</p>}
                                                </div>
                                                <div>
                                                    <label htmlFor="width" className="block text-sm font-medium mb-1">Width</label>
                                                    <input
                                                        id="width"
                                                        {...register("width")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="text"
                                                        onChange={handleInputChange}
                                                        placeholder="Width"
                                                    />
                                                    {errors.width && <p className="text-red-500 text-sm mt-1">{errors.width.message}</p>}
                                                </div>
                                                <div>
                                                    <label htmlFor="height" className="block text-sm font-medium mb-1">Height</label>
                                                    <input
                                                        id="height"
                                                        {...register("height")}
                                                        className={`p-2 appearance-none shadow-custom-light rounded-custom border w-full focus:outline-none focus:shadow-outline ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white placeholder-white shadow-none" : ''}`}
                                                        type="text"
                                                        placeholder="Height"
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.height && <p className="text-red-500 text-sm mt-1">{errors.height.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex justify-center mb-3">
                                    <button
                                        type="submit"
                                        className="w-full md:w-[40%] bg-customPurple hover:bg-purple-700 text-white py-2 rounded-md flex items-center justify-center"
                                    >
                                        Estimate Reverse Shipping Cost
                                        <FaCalculator className="ml-2" />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}