
// import { useState } from 'react';
// import { Button, Pagination, Tab, Tabs, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
// import Sidebar from '../../../component/sidebar/sidebar';
// import NavBar from '../../../component/navBar/navBar';
// import { useMyContext } from '../../../context/MyContext';
// import '../../../css/custom-scrollbar.css';

// const AllShipment = () => {
//   const [activeTab, setActiveTab] = useState('newOrder');
//   const { isDarkMode, isToggleSidebar } = useMyContext();

//   const tabs = [
//     { id: 'newOrder', label: 'New Orders' },
//     { id: 'processOrder', label: 'Processed Orders' },
//     { id: 'manifestList', label: 'Manifest List' },
//   ];

//   const newOrderData = [
//     { id: '001', customer: 'John Doe', status: 'Pending', date: '2024-09-01' },
//     { id: '002', customer: 'Jane Smith', status: 'Pending', date: '2024-09-02' },
//   ];

//   const processOrderData = []; // Empty data to show 'No data available' message
//   const manifestListData = [
//     { id: 'M001', manifest: 'Manifest A', date: '2024-09-03', status: 'In Progress' },
//     { id: 'M002', manifest: 'Manifest B', date: '2024-09-04', status: 'Completed' },
//   ];

//   return (
//     <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
//       <div className="z-10">
//         <NavBar />
//       </div>

//       <div className="flex flex-1 overflow-hidden">
//         {isToggleSidebar && (
//           <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}>
//             <Sidebar />
//           </div>
//         )}

//         <div className="container mx-auto p-4 shadow-custom-light rounded-custom md:mt-4 md:mb-4 md:mr-4 md:ml-4 sm:mb-2 sm:ml-2 sm:mr-2 sm:mt-8 dark:shadow-custom-dark custom-scrollbar overflow-y-auto">
//           <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mb-4">
//             <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} aria-label="Shipment Tabs">
//               {tabs.map(tab => (
//                 <Tab
//                   key={tab.id}
//                   label={tab.label}
//                   value={tab.id}
//                   className={`text-sm px-4 py-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark ${activeTab === tab.id ? 'bg-customPurple text-white' : ''}`}
//                 />
//               ))}
//             </Tabs>
//           </Box>

//           <div className="overflow-x-auto shadow-custom-light rounded-custom dark:shadow-none">
//             {activeTab === 'newOrder' && (
//               <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white"}`}>
//                 <Typography variant="h6" className="mb-4">New Orders</Typography>
//                 <TableContainer component={Paper}>
//                   <Table>
//                     <TableHead>
//                       <TableRow className='bg-[#0540a6dd]'>
//                         <TableCell className='text-white'>Order ID</TableCell>
//                         <TableCell className='text-white'>Customer</TableCell>
//                         <TableCell className='text-white'>Status</TableCell>
//                         <TableCell className='text-white'>Date</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {newOrderData.map((row) => (
//                         <TableRow key={row.id}>
//                           <TableCell>{row.id}</TableCell>
//                           <TableCell>{row.customer}</TableCell>
//                           <TableCell>{row.status}</TableCell>
//                           <TableCell>{row.date}</TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//                 <div className='flex justify-between items-center mt-4'>
//                   <p>Showing <b>1</b> of <b>50</b> results</p>
//                   <Pagination count={10} color="primary" size="small" />
//                 </div>
//               </div>
//             )}

//             {activeTab === 'processOrder' && (
//               <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white"}`}>
//                 <Typography variant="h6" className="mb-4">Processed Orders</Typography>
//                 <Typography className="text-gray-600">No data available for this tab.</Typography>
//               </div>
//             )}

//             {activeTab === 'manifestList' && (
//               <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white"}`}>
//                 <Typography variant="h6" className="mb-4">Manifest List</Typography>
//                 <TableContainer component={Paper}>
//                   <Table>
//                     <TableHead>
//                       <TableRow className='bg-[#0540a6dd]'>
//                         <TableCell className='text-white'>Manifest ID</TableCell>
//                         <TableCell className='text-white'>Manifest Name</TableCell>
//                         <TableCell className='text-white'>Date</TableCell>
//                         <TableCell className='text-white'>Status</TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {manifestListData.map((row) => (
//                         <TableRow key={row.id}>
//                           <TableCell>{row.id}</TableCell>
//                           <TableCell>{row.manifest}</TableCell>
//                           <TableCell>{row.date}</TableCell>
//                           <TableCell>{row.status}</TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//                 <div className='flex justify-between items-center mt-4'>
//                   <p>Showing <b>1</b> of <b>50</b> results</p>
//                   <Pagination count={10} color="primary" size="small" />
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AllShipment;

import { useState } from 'react';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import { Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { FaWeight } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi';
import { useMyContext } from '../../../context/MyContext';
import '../../../css/custom-scrollbar.css';

const AllShipment = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { isDarkMode, isToggleSidebar } = useMyContext();

  const tabs = [
    { id: 'new order', label: 'New order' },
    { id: 'process order', label: 'Process order' },
    { id: 'manifest list', label: 'Manifest List' },
    { id: 'track', label: 'Track' },
    { id: 'all orders', label: 'All Orders' },
  ];

  const newOrderData = [
    { orderId: 'AWB001', status: 'pending', customerName: 'John Doe', customerPhone: '1234567890', customerEmail: 'john@example.com', amount: '$100', channel: 'Online', dimensions: '30x20x10', createDate: '2023-09-05' },
    { orderId: 'AWB002', status: 'processed', customerName: 'Jane Smith', customerPhone: '0987654321', customerEmail: 'jane@example.com', amount: '$50', channel: 'Offline', dimensions: '20x15x10', createDate: '2023-09-06' },
  ];

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : newOrderData.map((_, index) => index));
  };

  const handleRowSelect = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleQuickAssign = (orderId) => {
    // Show courier details and partner name
    console.log(`Quick Assign for ${orderId}`);
  };

  const handleSync = () => {
    console.log('Syncing shipment history');
  };

  const handleAssignCourier = () => {
    console.log('Assigning courier');
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
        <NavBar />
    </div>
    <div className="flex flex-1">
    {isToggleSidebar && (
        <div
            className={`z-20 h-full w- fixed bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:relative md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            } >
            <Sidebar />
        </div>
        )}
<div className="container w-full mx-auto p-4 shadow-custom-light rounded-custom md:mt-4 md:mb-4 md:mr-4 md:ml-4 sm:mb-2 sm:ml-2 sm:mr-2 sm:mt-8 dark:shadow-custom-dark custom-scrollbar overflow-y-auto">
          <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
            <FaWeight className="mr-2" size={24} />
            All Orders
          </button>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`px-4 py-2 mr-8 w-full mb-2 rounded dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark ${activeTab === tab.id ? 'bg-customPurple hover:bg-purple-700 text-white dark:bg-customPurple dark:text-white' : 'dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>

         
          {activeTab === 'new order' && (
            <div className={`rounded-custom p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : 'bg-white'}`}>
              <table className="min-w-full  table-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr>
                    <th className="p-2 text-left">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th className="p-2 text-left">Order ID</th>
                    <th className="p-2 text-left">Status</th>
                    <th className="p-2 text-left">Customer Info</th>
                    <th className="p-2 text-left">Amount</th>
                    <th className="p-2 text-left">Channel</th>
                    <th className="p-2 text-left">Dimensions</th>
                    <th className="p-2 text-left">Create Date</th>
                    <th className="p-2 text-left">Quick Assign</th>
                  </tr>
                </thead>
                <tbody>
                  {newOrderData.map((row, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelect(index)}
                        />
                      </td>
                      <td className="p-2">{row.orderId}</td>
                      <td className="p-2">{row.status}</td>
                      <td className="p-2">
                        {row.customerName} <br />
                        {row.customerPhone} <br />
                        {row.customerEmail}
                      </td>
                      <td className="p-2">{row.amount}</td>
                      <td className="p-2">{row.channel}</td>
                      <td className="p-2">{row.dimensions}</td>
                      <td className="p-2">{row.createDate}</td>
                      <td className="p-2">
                        <Button variant="contained" color="primary" onClick={() => handleQuickAssign(row.orderId)}>
                          Quick Assign
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-between items-center mt-4">
                <Button variant="contained" color="primary" onClick={handleSync}>
                  Sync
                </Button>
                <Pagination count={10} color="primary" size="small" />
              </div>
            </div>
          )}

          {activeTab === 'process order' && (
            <div className={`rounded-custom p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : 'bg-white'}`}>
              <table className="min-w-full table-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr>
                    <th className="p-2 text-left">Order ID</th>
                    <th className="p-2 text-left">Order Status</th>
                    <th className="p-2 text-left">Customer Info</th>
                    <th className="p-2 text-left">Value</th>
                    <th className="p-2 text-left">Tracking Info</th>
                    <th className="p-2 text-left">Dimensions</th>
                    <th className="p-2 text-left">Channel</th>
                    <th className="p-2 text-left">Ship Date</th>
                    <th className="p-2 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Map your Process Order data here */}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllShipment;
