"use client"
import { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import { FiDownload } from 'react-icons/fi';
import { AiOutlineFileText } from 'react-icons/ai';
import { Button } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

const PageInvoices = () => {
    const { isDarkMode, isToggleSidebar } = useMyContext();
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const navigation  = useNavigate(); 

    const invoiceData = [
        { id: 'INV-001', date: '2024-08-27', gstNo: '27AABCU9603R1ZX', amount: '$1500.00', dueDate: '2024-09-15' },
        { id: 'INV-002', date: '2024-08-15', gstNo: '27AABCU9603R1ZX', amount: '$2000.00', dueDate: '2024-09-10' },
    ];

    // Calculate the index range for the current page
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = invoiceData.slice(startIndex, endIndex);

   
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };


 
    const handleDownload = (invoice) => {
        const doc = new jsPDF();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(20);
        doc.setTextColor(41, 128, 185); 

        doc.addImage("/assets/images/logo.png", "PNG", 10, 10, 50, 20);
        doc.text("INVOICE", 150, 20, { align: "right" });
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(`Invoice ID: ${invoice.id}`, 150, 40, { align: "right" });
        doc.text(`Date: ${invoice.date}`, 150, 50, { align: "right" });
        doc.text(`Due Date: ${invoice.dueDate}`, 150, 60, { align: "right" });
        doc.text("Your Company Name", 10, 40);
        doc.text("123 Business Street", 10, 50);
        doc.text("City, State, ZIP", 10, 60);
        doc.text(`GST No: ${invoice.gstNo}`, 10, 70);

        doc.text("Bill To:", 10, 90);
        doc.text("Client Name", 10, 100);
        doc.text("Client Address", 10, 110);
        doc.text("Client City, State, ZIP", 10, 120);

        doc.autoTable({
            startY: 140,
            head: [['Item', 'Quantity', 'Unit Price', 'Total']],
            body: [
                ['Item 1', '2', '$500', '$1000'],
                ['Item 2', '1', '$500', '$500'],
            ],
            theme: 'striped',
            headStyles: { fillColor: [41, 128, 185] },
        });

        const finalY = doc.lastAutoTable.finalY || 200;
        doc.setFont("helvetica", "bold");
        doc.text(`Total Amount: ${invoice.amount}`, 150, finalY + 20, { align: "right" });
        doc.setFont("helvetica", "italic");
        doc.setFontSize(10);
        doc.text("Thank you for your business!", 105, 280, { align: "center" });
        doc.save(`Invoice_${invoice.id}.pdf`);
    };


    const handleNavigation = ()=>{
        navigation ("/dashboard");
    }



    return (

        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div
                        className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768 
                              ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                              : {}
                          } >
                        <Sidebar />
                    </div>
                )}
        
            <div className="container mx-auto p-4 shadow-custom-light rounded-custom dark:shadow-custom-dark md:ml-4 md:mt-4 md:mb-8 md:mr-4 overflow-y-auto">
                <button  onClick={handleNavigation}
                  className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
                    <AiOutlineFileText className="mr-2" size={24} /> 
                    Invoice Dashboard
                </button>
                <div className="overflow-x-auto shadow-custom-light rounded-custom dark:shadow-none mb-6">
                    <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
                        <h2 className="text-xl font-semibold mb-4"></h2>

                        {invoiceData.length > 0 ? (
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto">
                                    <thead className="bg-[#0540a6dd] text-white text-[14px]">
                                        <tr>
                                            <th className="p-2 text-left">Invoice ID</th>
                                            <th className="p-2 text-left">Invoice Date</th>
                                            <th className="p-2 text-left">GST No</th>
                                            <th className="p-2 text-left">Invoice Amount</th>
                                            <th className="p-2 text-left">Due Date</th>
                                            <th className="p-2 text-left">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRows.map((row, index) => (
                                            <tr key={index} className="border-b">
                                                <td className="p-2">{row.id}</td>
                                                <td className="p-2">{row.date}</td>
                                                <td className="p-2">{row.gstNo}</td>
                                                <td className="p-2">{row.amount}</td>
                                                <td className="p-2">{row.dueDate}</td>
                                                <td className="p-2">
                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        size="small"
                                                        className='capitalize bg-blue-400'
                                                        onClick={() => handleDownload(row)}
                                                    >
                                                        <FiDownload size={20} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-gray-600">No invoices available.</p>
                        )}
                        <div className="flex justify-between items-center mt-4 pb-4 pr-4 pl-4">
                            <p>Showing <b>{startIndex + 1}</b> to <b>{Math.min(endIndex, invoiceData.length)}</b> of <b>{invoiceData.length}</b> results</p>
                            <Pagination
                                count={Math.ceil(invoiceData.length / rowsPerPage)}
                                color="primary"
                                size="small"
                                sx={{
                                    '.MuiPaginationItem-text': {
                                      color: isDarkMode ? 'white' : 'defaultColor', 
                                    },
                                    '.MuiPaginationItem-root': {
                                    },
                                  }}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
       </div>
       </div>
    );
};

export default PageInvoices;
