import React from 'react';
import { Download } from 'lucide-react';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import { Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import { useNavigate } from 'react-router-dom';

const ReportsDropdown = () => {
    const { isDarkMode,isToggleSidebar } = useMyContext();
    const navigate = useNavigate();

    const handleNavigate = ()=>{
        navigate("/dashboard");
    }

    const reports = [
        { name: 'MIS Report', columns: ['Order Info', 'Order Total', 'Order Status', 'Tracking Info', 'Current Shipment Status'] },
        { name: 'Orders Report', columns: ['Order Status', 'Total Orders'] },
        { name: 'Order Product Report', columns: ['Order Status', 'Total Orders'] },
        { name: 'Current Shipment Report', columns: ['Order Status', 'Total Orders'] },
        { name: 'NDR Report', columns: ['Order Status', 'Total Orders'] },
        { name: 'Product-wise Report', columns: ['Product Name', 'SKU', 'Total', 'Transit', 'Delivered', 'Undelivered', 'RTO', 'RTO Delivered', 'Others'] },
        { name: 'View Reports', columns: ['Report Date', 'Report Title', 'Report Type', 'Generated By', 'Status'] },
    ];

    const handleDownload = (report) => {
        const doc = new jsPDF();
        doc.text(`Report: ${report.name}`, 10, 10);
        report.columns.forEach((column, index) => {
            doc.text(`${index + 1}. ${column}`, 10, 20 + index * 10);
        });
        doc.save(`${report.name}.pdf`);
    };

    return (
        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div
                        className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768 
                              ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                              : {}
                          }  > 
                        <Sidebar />
                    </div>
                )}
      <div className="container mx-auto p-6 bg-white rounded-custom shadow-custom-light dark:shadow-custom-dark max-w-full  dark:bg-[rgba(1,14,78,0.96)] font-sans md:ml-4 md:mt-4 md:mb-8 md:mr-4  custom-scrollbar overflow-y-auto">
                    <button onClick={handleNavigate}
                     className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-4 py-2 rounded-full shadow-md">
                        Reports Dashboard
                    </button>
                    <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                        {reports.map((report) => (
                            <div key={report.name} className={`bg-white shadow-custom-light rounded-custom p-4 mb-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]  dark:text-white dark:shadow-custom-dark ' : 'bg-white'}`}>
                                <div className="flex items-center justify-between mb-2">
                                    <h3 className="text-lg font-bold">{report.name}</h3>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        size="small"
                                        className="capitalize bg-blue-500 hover:bg-blue-600 text-white"
                                        onClick={() => handleDownload(report)}
                                    >
                                        <Download size={20} />
                                        
                                    </Button>
                                </div>
                                <div className="overflow-x-auto overflow-hidden">
                                    <table className="min-w-full table-auto border-collapse">
                                        <thead className="bg-[#0540a6dd] text-white text-sm">
                                            <tr>
                                                {report.columns.map((column) => (
                                                    <th
                                                        key={column}
                                                        className="p-2 text-left text-xs font-medium uppercase tracking-wider border-b border-gray-300"
                                                    >
                                                        {column}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            <tr>
                                                {report.columns.map((column, index) => (
                                                    <td key={index} className="p-2 whitespace-nowrap text-sm text-gray-500">
                                                        {column}
                                                    </td>
                                                ))}

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ReportsDropdown;
