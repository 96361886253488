"use client"
import React from 'react';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import Pagination from '@mui/material/Pagination';
import '../../../css/custom-scrollbar.css';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const ShippingCharges = () => {
  const {isDarkMode,isToggleSidebar}=useMyContext();
  const navigation = useNavigate();
   
  const handleNavigation = () =>{
    navigation("/dashboard");
  }

  const shippingData = [
    { id: '1', customerInfo: 'John Doe', trackingInfo: '123456789', charges: '$10.00', dimensions: '10x10x10 cm', assignmentDate: '2024-08-29' },
    { id: '2', customerInfo: 'Jane Smith', trackingInfo: '987654321', charges: '$20.00', dimensions: '20x15x10 cm', assignmentDate: '2024-08-28' },
  ];

  const renderTableHeaders = () => {
    return ['Order ID', 'Customer Info', 'Tracking Info', 'Charges Applied', 'Dimensions', 'Order Assignment Date'];
  };

  const renderTableRow = (row) => (
    <tr key={row.id} className="border-b dark:border-gray-600">
      <td className="p-2">{row.id}</td>
      <td className="p-2">{row.customerInfo}</td>
      <td className="p-2">{row.trackingInfo}</td>
      <td className="p-2">{row.charges}</td>
      <td className="p-2">{row.dimensions}</td>
      <td className="p-2">{row.assignmentDate}</td>
    </tr>
  );

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
        <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
            <div
                className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                style={
                  window.innerWidth < 768 
                    ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                    : {}
                } >
                <Sidebar />
            </div>
        )}
      {/* <div className="dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-light dark:shadow-none rounded-custom p-4"> */}
      <div className="dark:bg-[rgba(1,14,78,0.96)] w-full dark:text-white shadow-custom-light dark:shadow-none rounded-custom mt-4 p-4  md:ml-4 md:mt-4 md:mb-8 md:mr-4  custom-scrollbar overflow-y-auto">
      <button  onClick={handleNavigation} 
                    className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 dark:bg-customPurple dark:hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
                        <FaCcMastercard className="mr-2" size={24} />
                       Shipment Charges 
                    </button>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-[#0540a6dd] text-white text-[14px]">
              <tr>
                {renderTableHeaders().map((header, index) => (
                  <th key={index} className="p-2 text-left">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {shippingData.map(row => renderTableRow(row))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <p>Showing <b>1</b> of <b>50</b> results</p>
          <Pagination
           sx={{
            '.MuiPaginationItem-text': {
              color: isDarkMode ? 'white' : 'defaultColor', 
            },
            '.MuiPaginationItem-root': {
            },
          }}
           count={10} color="primary" size="small" />
        </div>
      </div>
      </div>
      </div>
    
  );
};

export default ShippingCharges;
