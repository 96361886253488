import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { RxDashboard } from "react-icons/rx";
import { FaAngleRight,FaAngleDown } from "react-icons/fa";
import { IoCreateOutline } from "react-icons/io5";
import { CiCircleAlert, CiBank, CiSettings } from "react-icons/ci";
import { LuWarehouse } from "react-icons/lu";
import { PiAsteriskSimpleThin } from "react-icons/pi";
import { TbReport } from "react-icons/tb";
import { GoMilestone } from "react-icons/go";
import { MdSupportAgent } from "react-icons/md";
import { MdGroups2 } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
import { useMyContext } from "../../context/MyContext";
import '../css/custom-scrollbar.css';
import { Toaster,toast } from "react-hot-toast";
// import { useEffect } from "react";

const Sidebar = ({setAuth}) => {
  const [activeTab, setActiveTab] = useState(null);
  const { isToggleSidebar, isDarkMode } = useMyContext();
  const navigate = useNavigate();

 
  const toggleSubmenu = (index, href) => {
    if (href) {
      navigate(href);
    } else {
      setActiveTab(activeTab === index ? null : index);
    }
  };


  // useEffect(() => {
  //   const storedUser = JSON.parse(localStorage.getItem('user'));
  // }, []);

  


  const menuItems = [
    {
      title: "Dashboard",
      icon: <RxDashboard />,
      href: "/dashboard",
      submenu: [],
    },
    {
      title: "Shipments",
      icon: <IoCreateOutline />,
      submenu: [
        { title: "Forward Shipment", href: "/shipments/forward" },
        // { title: "Reverse Shipment", href: "/shipments/reverse" },
        // { title: "Pickup Request", href: "/shipments/pickuprequest" },
        // { title: "Track Shipments", href: "/shipments/tracking" },
      ],
    },
    {
      title: "Warehouse",
      icon: <LuWarehouse />,
      submenu: [
        { title: "Add Warehouse", href: "/warehouse/add" },
        { title: "All Warehouses", href: "/warehouse/all" },
      ],
    },
    {
      title: "Disputes",
      icon: <CiCircleAlert />,
      submenu: [
        // { title: "NDR", href: "/disputes/NDR" },
        // { title: "RTO", href: "/disputes/RTO" },
        // { title: "Weight Disputes", href: "/disputes/weight" },
      ],
    },
    {
      title: "Payments",
      icon: <CiBank />,
      submenu: [
        // { title: "remmitance", href: "/payments/remmitance" },
        // { title: "Invoice", href: "/payments/invoice" },
        // { title: "Wallet Balance", href: "/payments/walletbalance" },
        // { title: "Payments History", href: "/payments/history" },
      ],
    },
    {
      title: "Risk",
      icon: <PiAsteriskSimpleThin />,
      submenu: [
        // { title: "Claims", href: "/risk/claims/lost&damages" },
      ],
    },
    {
      title: "Services",
      icon: <GoMilestone />,
      submenu: [],
    },
    {
      title: "Employee",
      icon: <MdGroups2 />,
      submenu: [
        // { title: "Add Employee", href: "/employee/add" },
        // { title: "Add Department", href: "/employee/settings" },
      ],
    },
    {
      title: "Customer",
      icon: <RiGroupLine />,
      submenu: [
        // { title: "UpdateKYC", href: "/customers/updateKYC" },
        // { title: "All Customers", href: "/customers/all" },
      ],
    },
    {
      title: "Settings",
      icon: <CiSettings />,
      submenu: [
        { title: "Rate Calculator", href: "/settings/rateCalculator" },
        // { title: "Servicable Pincodes", href: "/settings/account" },
        // { title: "Rate Card", href: "/settings/account" },
        // { title: "Zone", href: "/settings/account" },
        // { title: "Add Pincodes", href: "/settings/account" },
        // { title: "Set Rate", href: "/settings/account" },
      ],
    },
    {
      title: "Reports" ,
      // href: "/reports",
      icon: <TbReport />,
      submenu: [],
    },
    {
      title: "Help",
      icon: <MdSupportAgent />,
      submenu: [],
    },
  ];

  const handleLogout=()=>{
    localStorage.removeItem("user");
    navigate('/login')
    localStorage.removeItem('authToken')
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
    toast.success("LogOut Sucessfully!")
  }

  return (
    <>
      {isToggleSidebar && (
        <div className="w-64 h-full flex flex-col  transform transition-transform duration-300 pl-2 custom-scrollbar ">
          <ul className="flex-1">
            {menuItems.map((item, index) => (
              <li key={index}>
                <Button
                  sx={{
                    fontFamily: 'var(--font-custom-open-sans)',
                    textTransform: 'none',
                    color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                    width: '100%',
                    textAlign: 'left',
                    fontWeight:'bold',
                    display: 'flex',
                    // paddingLeft:'1.5rem',
                    alignItems: 'center',
                    padding: '0.75rem 1rem',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'rgba(128,128,128, 0.3)',
                      color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => toggleSubmenu(index, item.href)}
                >
                  <span className="icon mr-2 text-xl text-custom-green">
                    {item.icon}
                  </span>
                  <span className="pl-3">
                  {item.title}
                  </span>
                  <span
                className="arrow ml-auto"
              >
                {activeTab === index ? <FaAngleDown /> : <FaAngleRight />}
              </span>
                </Button>
                {item.submenu.length > 0 && activeTab === index && (
                  <div className="submenuWrapper pl-6">
                    <ul className="submenu text-custom-gray border-l pl-4 border-gray-600">
                      {item.submenu.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className={`py-0 px-0 dark:text-white ${
                            activeTab === index
                              ? "text-gray-700 font-bold dark:text-customPurple"
                              : ""
                          }`}
                        >
                          <Link
                            to={subItem.href}
                            className="text-sm font-semibold hover:opacity-70 transition-opacity duration-300"
                            aria-label={subItem.title}
                            style={{
                              fontFamily: 'var(--font-custom-open-sans)',
                              color: isDarkMode ? 'white' : 'rgba(6, 6, 6, 0.616)',
                            }}
                          >
                            {subItem.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <span className="mt-12 mb-12 ml-10 w-32 font-sans">
          <Button onClick={handleLogout}  sx={{ textTransform: 'capitalize', 
            color : isDarkMode ? 'white' : 'white',
            backgroundColor:"#3B82F6",
            marginLeft:"42px",
            }} 
            className=' text-white bg-blue-500 hover:text-white hover:bg-blue-600 w-32 font-sans h-8 '>
          LogOut
        </Button>
        </span>
        </div>
      )}
    </>
  );
};

export default Sidebar;
