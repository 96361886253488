"use client"

import { useState } from 'react';
import '../../../css/custom-scrollbar.css';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MdDashboard } from 'react-icons/md';

export default function NDRDashboard() {
  const [activeTab, setActiveTab] = useState('Action Pending');
  const navigate = useNavigate();
  const { isDarkMode, isToggleSidebar } = useMyContext()
  const tabs = ['Action Pending', 'Action Taken', 'RTO Delivered', 'All Details'];

  const renderTableHeaders = () => {
    const commonHeaders = ['Order ID', 'AWB No.', 'Customer Info', 'Value', 'Last Updated', 'Remark'];
    if (activeTab === 'Action Pending') {
      return [...commonHeaders, 'Action'];
    }
    return commonHeaders;
  };

  const renderTableRow = (index) => {
    const commonCells = [
      <td key="orderId" className='p-2'>092099129</td>,
      <td key="awbNo" className='p-2'>AWB123456</td>,
      <td key="customerInfo" className='p-2'>John Doe</td>,
      <td key="value" className='p-2'>₹ 1000</td>,
      <td key="lastUpdated" className='p-2'>2023-08-23</td>,
      <td key="remark" className='p-2'>Pending</td>
    ];

    if (activeTab === 'Action Pending') {
      commonCells.push(
        <td key="action" className='p-2'>
        <div className='flex space-x-2 capitalize'>
          <Button 
            className='capitalize'  
            sx={{ textTransform: 'capitalize', fontSize: '12px', padding: '4px 8px', minHeight: '24px' }}
            variant="contained" 
            size="small" 
            color="primary"
          >
            Reattempt
          </Button>
          <Button 
            sx={{ textTransform: 'capitalize', fontSize: '12px', padding: '4px 8px', minHeight: '24px' }}
            variant="contained" 
            size="small" 
            color="secondary"
          >
            RTO
          </Button>
          <Button 
            sx={{ textTransform: 'capitalize', fontSize: '12px', padding: '4px 8px', minHeight: '24px' }}
            variant="contained" 
            size="small" 
            color="warning"
          >
            Reschedule
          </Button>
        </div>
      </td>
      
      );
    }

    return (
      <tr key={index} className='border-b'>
        {commonCells}
      </tr>
    );
  };

  const handleTabClick = (tab) => {
    if (tab === 'RTO Delivered') {
      navigate('/disputes/RTO');
    }
    setActiveTab(tab);

  };

  return (


    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
            <Sidebar />
          </div>
        )}

        <div className='w-full overflow-y-auto dark:bg-[rgba(1,14,78,0.96)] custom-scrollbar dark:shadow-custom-dark shadow-custom-light rounded-custom'>
          <div className="container mx-auto p-4">
            <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-600 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
              <MdDashboard className="mr-2" size={24} />
              NDR Dashboard
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 w-full bg-cus">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`px-4 py-2 md:mr-12 font-sans rounded ${activeTab === tab ? ' text-white   dark:bg-customPurple bg-customPurple hover:bg-purple-700 dark:hover:bg-purple-700 dark:text-white' : ' dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'
                    }`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>

            <div className='rounded-custom shadow-custom-light dark:shadow-none p-4'>
              <div className="overflow-x-auto">
                <table className="min-w-full w-full table-auto dark:text-white font-sans dark:bg-[rgba(1,14,78,0.96)]">
                  <thead className="bg-[#0540a6dd] text-white text-[14px]">
                    <tr>
                      {renderTableHeaders().map((header, index) => (
                        <th key={index} className="p-2 text-left  w-1/6">{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(7)].map((_, index) => renderTableRow(index))}
                  </tbody>
                </table>
              </div>

              <div className='flex justify-between items-center mt-4 dark:text-white'>
                <p>Showing <b>1</b> of <b>50</b> results</p>
                <Pagination
                 sx={{
                  '.MuiPaginationItem-text': {
                    color: isDarkMode ? 'white' : 'defaultColor', 
                  },
                  '.MuiPaginationItem-root': {
                  },
                }}
                 count={10} color="primary" size="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
