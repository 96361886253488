
import { Pagination } from '@mui/material';
import { MdDashboard } from 'react-icons/md'; 
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';

export default function RTODashboard() {
   

  const {isDarkMode,isToggleSidebar}=useMyContext()
  const renderTableHeaders = () => {
    const headers = ['Order ID', 'AWB No.', 'Customer Info', 'Value', 'Last Updated', 'Remark'];
    return headers;
  };

  const renderTableRow = () => {
    const cells = [
      <td key="orderId" className='p-2'>092099129</td>,
      <td key="awbNo" className='p-2'>AWB123456</td>,
      <td key="customerInfo" className='p-2'>John Doe</td>,
      <td key="value" className='p-2'>₹ 1000</td>,
      <td key="lastUpdated" className='p-2'>2023-08-23</td>,
      <td key="remark" className='p-2'>Pending</td>
    ];

    return (
      <tr key={Math.random()} className='border-b'>
        {cells}
      </tr>
    );
  };

  return (

    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
      <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
      {isToggleSidebar && (
        <div
          className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
          style={
            window.innerWidth < 768 
              ? { top: "140px", height: "calc(100vh - 115px)", overflowY: 'auto' }
              : {}
          }>
          <Sidebar />
        </div>
      )}
      <div className='w-full mt-4 mb-4 dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark rounded-custom overflow-y-auto'>
        <div className="container mx-auto p-4">
          <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple dark:hover:bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
            <MdDashboard className="mr-2" size={24} /> 
            RTO Dashboard
          </button>

          <div className='dark:bg-[rgba(1,14,78,0.96)] dark:text-white shadow-custom-light dark:shadow-none rounded-custom p-4'>
            <div className='overflow-x-auto'>
              <table className='min-w-full table-auto'>
                <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                  <tr>
                    {renderTableHeaders().map((header, index) => (
                      <th key={index} className='p-2 text-left'>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[...Array(7)].map((_, index) => renderTableRow(index))}
                </tbody>
              </table>
            </div>
            <div className='flex justify-between items-center mt-4'>
              <p>Showing <b>1</b> of <b>50</b> results</p>
              <Pagination 
               sx={{
                '.MuiPaginationItem-text': {
                  color: isDarkMode ? 'white' : 'defaultColor', 
                },
                '.MuiPaginationItem-root': {
                },
              }}
               count={10} color="primary" size="small" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
