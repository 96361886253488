//Base Url
export const Base_Url ="https://dev.pickupxpress.com/api/"
//warehouse add
export const Addwarehouse='createwarehouse'
// ware house list 
export const Warehouselist='warehouseslist'
//price estimetor
export const priceEstimator='priceEstimator'
// create shippment
export const createShippment='createShipment'

export const forwardorderlist ='forwardorderlist'